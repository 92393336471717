<script setup lang="ts">
import type { BaseLogoPropsSize } from "./Image.vue";
import { type FALLBACK, SEGMENT_TRACK_EVENTS } from "~/constants";

export interface BaseBannerProps {
  banner: IModelPoster;
  slideImageClass?: string;
  slideWidth?: BaseLogoPropsSize;
  slideHeight?: BaseLogoPropsSize;
  fallbackVariant?: (typeof FALLBACK)[keyof typeof FALLBACK];
}
const props = withDefaults(defineProps<BaseBannerProps>(), {
  slideImageClass: "object-cover",
  slideWidth: "full",
  slideHeight: "full",
});

const segment = useSegment();
const sourceLocation = useSourceLocation();

const handleBannerClick = () => {
  if (!props.banner.linkUrl)
    return;

  return segment.track(SEGMENT_TRACK_EVENTS.CLICK_ON_BANNERS, {
    url: props.banner.linkUrl,
    source_location: sourceLocation.value,
  });
};
</script>

<template>
  <a v-if="banner.linkUrl && banner.linkTarget" :href="banner.linkUrl" :target="banner.linkTarget || '_blank'" class="w-full h-full">
    <div class="h-full">
      <BaseImage
        :width="slideWidth"
        :height="slideHeight"
        :src="banner?.imageUrl"
        :alt="banner.imageUrl"
        :class="slideImageClass"
        :fallback-variant="props.fallbackVariant"
        @click="handleBannerClick"
      />
    </div>
  </a>
  <BaseImage
    v-else
    :width="slideWidth"
    :height="slideHeight"
    :src="banner.imageUrl"
    :alt="banner.imageUrl"
    :class="props.slideImageClass"
    :fallback-variant="props.fallbackVariant"
  />
  <div class="absolute left-1/2 bottom-16 w-full max-w-[80%] text-center z-30 text-white translate-x-[-50%]">
    <h3 v-if="banner.title" class="text-heading-2" v-html="banner.title" />
    <div v-if="banner.description" class="text-body-medium mt-4" v-html="banner.description" />
  </div>
</template>
